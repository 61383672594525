// Creates and returns an observer object that can watch one or more sticky elements and 
//   fires a 'sticky-change' event on it when it sticks/unsticks.
// Exposes add(element) method to add children to be observed
// scrollRoot is the nearest containing scrollable element that
//   the added sticky elements will stick against
// This requires browser support (or a polyfill) for IntersectionObserver 

function createStickyObserver(scrollRoot = null)
{
    let observables = [];
    const calculateTopSentinalStyles = function(element)
    {
        let elementStyles = window.getComputedStyle(element);
        if (elementStyles.position != 'sticky' && elementStyles.position != '-webkit-sticky') {
            return {
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 'auto',
                height: '0',
                visibility: 'hidden',
            };
        }

        return {
            position: 'absolute',
            bottom: `calc(100% + ${elementStyles.top != 'auto' ? elementStyles.top : '0px'} + 1px)`,
            top: 'auto',
            left: 0,
            width: '100%',
            height: '16px',
            visibility: 'hidden',
        };
    };

    const observer = new IntersectionObserver((entries, observer) => {
        let event = document.createEvent('CustomEvent');
        for (let entry of entries) {
            let stickyElement = entry.target.parentElement;

            //started sticking
            if (entry.boundingClientRect.bottom < entry.rootBounds.top) {
                event.initCustomEvent('sticky-change', false, false, {isStuck: true});
                stickyElement.dispatchEvent(event);
            }

            //stopped sticking
            if (entry.boundingClientRect.bottom >= entry.rootBounds.top &&
                entry.boundingClientRect.bottom < entry.rootBounds.bottom) {
                event.initCustomEvent('sticky-change', false, false, {isStuck: false});
                stickyElement.dispatchEvent(event);
            }
        }
    }, { threshold: [0], root: scrollRoot });

    const add = function(element)
    {
        let elementStyles = window.getComputedStyle(element);

        let topSentinalStyles = calculateTopSentinalStyles(element);

        let topSentinal = document.createElement('div');
        topSentinal.classList.add('sticky-sentinal-top');
        Object.assign(topSentinal.style, topSentinalStyles);
        // topSentinal.style = {...topSentinal.style, ...topSentinalStyles};
        element.appendChild(topSentinal);
        observer.observe(topSentinal);
        observables.push(element);
    };

    const recalculateSentinals = function()
    {
        for (let element of observables) {
            let topSentinalStyles = calculateTopSentinalStyles(element);
            let topSentinal = element.querySelector('.sticky-sentinal-top');
            Object.assign(topSentinal.style, topSentinalStyles);
        }
    };

    //recalculate sentinal position if window size changes
    let resizeTimeout;
    window.addEventListener('resize', e => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(recalculateSentinals, 250);
    });

    return {
        add
    };
}
