$(function() {
    var under_21_url = $('.popinfo').data('under-21-url');
    var current_url = window.location.pathname;
    var age_cookie = Cookies.get('_SBNWCSAC');

    if ((under_21_url == current_url)
        || (age_cookie == 'over')
        || ($('html').is('.sb-in-admin, .sb-has-toolbar'))
        || ($('.sb-error-page').length > 0)) {
        return false;
    }

    if (age_cookie == 'under') {
        window.location.href = '/under-21';
    }

    var dialog = new jqueryDialogNWCS('body', function() {
        dialog.dialog.append(`
<h1 class="title">
    Are you 21 years old or older?
</h1>
<div class="body">
    In order to enter this site, you must be at least 21 years of age.
</div>
<div class="buttons">
    <a class="button age-over-21" id="age-over-21">Yes, I am over 21</a>
    <a class="button age-under-21" id="age-under-21">No, I am not yet 21</a>
</div>
`);
    }, {
        id: 'age-check-dialog',
        position: 'center',
        stem: false,
        underlay: true,
        close: false,
        preventClose: true,
        fixed: false
    }).open();

    $(dialog).on('afteropen', function() {
        var $over_button = $('#age-over-21');
        var $under_button = $('#age-under-21');

        $('#age-check-dialog-underlay').on('click', function(e) {
            e.stopPropagation();
            return false;
        });

        $over_button.on('click', function() {
            Cookies.set('_SBNWCSAC', 'over');
            window.location.href = current_url || '/';
            return false;
        });

        $under_button.on('click', function() {
            Cookies.set('_SBNWCSAC', 'under');
            window.location.href = under_21_url;
            return false;
        });
    });
});
